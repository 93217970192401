import * as Sentry from '@sentry/browser';

declare global {
  interface Window {
    lwhb:
      | {
          load: (ad: Element) => void;
          loaded: boolean;
          cmd: Array<() => void>;
        }
      | undefined;
  }
}

function handleLoadAdInDevelopment(
  el: IntersectionObserverEntry,
  device: 'dt' | 'mb',
) {
  console.log('DEVELOPMENT: loading FAKE ad: ', el.target.firstElementChild);

  if (el.target.firstElementChild instanceof HTMLElement === false) {
    return;
  }

  const classes = ['flex', 'justify-center', 'items-center'];
  const adEl = el.target.firstElementChild;
  adEl.classList.add(
    device === 'dt' ? 'w-[920px]' : 'w-[320px]',
    ...classes
  );

  adEl.innerText = adEl.attributes.getNamedItem('data-tid')?.value ?? 'unknown';
}

function createLeeAdObserver() {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const filterClass = window.innerWidth > 768 ? 'dt' : 'mb';
          if (entry.target.className.includes(`txs-a ${filterClass}`)) {
            const el = entry.target.firstElementChild;
            if (!el) return;

            if (process.env.NODE_ENV === 'development') {
              handleLoadAdInDevelopment(entry, filterClass);
              observer.unobserve(entry.target);
              return;
            }

            if (window.lwhb?.loaded) {
              window.lwhb.load(el);
            } else {
              window.lwhb?.cmd.push(() => window.lwhb?.load(el));
            }
            observer.unobserve(entry.target);
          }
        }
      });
    },
    {
      root: null,
      rootMargin: '600px',
    },
  );
  return {
    add: (nodes) => {
      nodes =
        Array.isArray(nodes) || nodes instanceof NodeList
          ? [...nodes]
          : [nodes];

      nodes.forEach((ad) => {
        if (ad.className.includes('txs-a')) {
          observer.observe(ad);
        }
      });
    },
  };
}

export default createLeeAdObserver;
